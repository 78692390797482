import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu';
import {TopMenu} from './TopMenu'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import { useState } from 'react'

export function Header() {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


	return(

      <header style={{backgroundColor: 'var(--blue)', padding: '20px 16px'}}>
		<Grid container justify="center" sx={{alignItems: 'center'}}>
		<Grid item xs={0} md={1}></Grid>
		<Grid item xs={6} md={3}>
		<a href="/">
		<img src="/chs-logo.png" width='150' alt="Early Stage Logo" style={{marginTop: '25px', marginBottom: '19px'}} />
		</a>
		</Grid>
		<Grid item xs={6} md={7} sx={{textAlign: 'right', marginTop: '12px', }}>

		{/*
		<Box sx={{display: {xs: 'none', md: 'block'}}}>
		<TopMenu />
		</Box>
		<Box sx={{display: {xs: 'block', md: 'none'}}}>
		<Button id='basic-button' aria-controls={open ? 'basic-menu' : undefined }
		aria-haspopup='true'
		aria-expanded = {open? 'true' : undefined }
		onClick={handleClick}
		sx = {{color: 'white'}}
		>
			<MenuIcon />
		</Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <a href='/insights' style={{textDecoration: 'none', color: 'black'}}><MenuItem onClick={handleClose}>Insights</MenuItem></a>
        <a href='/contact' style={{textDecoration: 'none', color: 'black'}}><MenuItem onClick={handleClose}>Contact us</MenuItem></a>
      </Menu>
		

		</Box>
		*/}
		</Grid>
		<Grid item xs={0} md={1}></Grid>

		</Grid>
		</header>
	)}

import Typography from '@mui/material/Typography'



export function HomePageBigLink({ text, url, pageType }) {

	return(
		<>
		<a href={"/" + pageType + "/" + url} style={{textDecoration: 'none'}}> <Typography variant='h2' sx={{margin: '132px 0', color: 'black', fontSize: {xs: '2.75rem', md: '2.45rem'} }}>{ text }</Typography></a>
		</>
	)}

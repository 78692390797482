import {ArticlesList} from './ArticlesList'
import { Helmet } from 'react-helmet'
import Typography from '@mui/material/Typography'


export function InsightsIndex({db}) {

	return(
		<>
		<Helmet>
		<title>Insights | Convex Hull Strategy</title>
		<meta name="description" content="Insights for business leaders" />
		</Helmet>

		<Typography variant='h3' sx={{mt: 5, pb: 8}}>Insights</Typography>
		<ArticlesList db={db} indexType='posts' />

		</>

	)
}


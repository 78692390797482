import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Helmet } from 'react-helmet'
import { useState } from 'react'
import { collection, addDoc } from 'firebase/firestore'

export function Contact({db}) {

		const [ name, setName ] = useState('')
		const [ company, setCompany ] = useState('')
		const [ email, setEmail ] = useState('')
		const [ message, setMessage ] = useState('')
		const [ formError, setFormError ] = useState(false)
		const [ submissionSuccess, setSubmissionSuccess ] = useState(false)

	 async function formSubmit() {
		setFormError(false)
		if ( name && company && email && message ) {
			const docRef = await addDoc(collection(db, "contactForms"), {
				name: name,
				company: company,
				email: email,
				message: message
			});
			if (docRef) {
				setSubmissionSuccess(true)
				setName('')
				setCompany('')
				setEmail('')
				setMessage('')
			}
			console.log("doc written with id: ", docRef.id)
		}
		 else {
			 setFormError(true)
		 }
	}

	return (

		

		<>
		<Helmet>
		<title>Contact us | Convex Hull Strategy</title>
		<meta name="description" content="Companies engage us to provide specialists to their teams." />
		</Helmet>


		<h1>Contact us</h1>



		<form>
		<p><TextField label="Name" variant="outlined" sx={{width: '100%', maxWidth: '500px'}} value={name} onChange={(e) => setName(e.target.value)} /></p>
		<p><TextField label="Company" variant="outlined" sx={{width: '100%', maxWidth: '500px'}} value={company} onChange={(e) => setCompany(e.target.value)}  /></p>
		<p><TextField label="Email Address" variant="outlined" sx={{width: '100%', maxWidth: '500px'}} value={email} onChange={(e)=> setEmail(e.target.value)} /></p>
		<p><TextField label="Message" multiline rows={4} variant="outlined" sx={{width: '100%', maxWidth: '500px'}} value={message} onChange={(e) => setMessage(e.target.value)} /></p>
		<Button variant="outlined" sx={{color: 'black', border: 'black solid 1px'}} onClick={formSubmit}>Submit</Button>

		</form>

		{ formError &&  <p>There is an error in submitting your form. Please check you have completed all fields.</p> }
		{ submissionSuccess && <p>Thank you for contacting us.</p> } 





		</>

	)
}

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'



export function Footer() {

	return(
		<footer>
		<Box sx={{backgroundColor: 'primary.dark', padding: '40px 30px'}}>
		<Grid container>
		<Grid item xs={0} md={1}></Grid>
		<Grid item xs={12} md={10}>



		<p>
		<img src="/chs-logo.png" width="150" alt="Early Stage Logo" />
		</p>
		<ul>
		<li><a href="/site-terms">Site terms</a></li>
		<li><a href="/privacy">Privacy policy</a></li>
		</ul>

		</Grid>
		<Grid item xs={0} md={1}></Grid>
		</Grid>
		</Box>
		</footer>
	)}

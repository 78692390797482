import {useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { collection, query, where, doc, getDocs } from 'firebase/firestore'
import Grid from '@mui/material/Grid'
import { Helmet } from 'react-helmet'


export function ArticlePage({db, pageType}) {

	let params = useParams();

	const [ articleData, setArticleData ] = useState({})


	useEffect(() => {
		async function GetArticleData() {

			{/*CHANGE DOC REF TO PULL ARTICLE BY URL*/}
			
		const q = query(collection(db, "posts"), where('url', '==', params.article_url))

			
			/*NEED TO ADD ERROR HANDLING IF NONE 404 response*/
			const querySnapshot = await getDocs(q);
			let docs = []
			querySnapshot.forEach(d => docs.push({id: d.id, ...d.data()}))
			docs[0] ? setArticleData(docs[0]) : setArticleData([])


		}
		GetArticleData();

	}, [db])

	useEffect(() => {
		document.title = articleData.name;
	}, [articleData]);



	return(

		<>
		<Helmet>
		{articleData.name && <title>{articleData.name} | Early Stage</title>}
		{articleData.metaDescription && <meta name="description" content={articleData.metaDescription} />}
		</Helmet>

		<section style={{padding: '50px 10px'}}>
		<Grid container>
		<Grid item xs={0} md={2}></Grid>
		<Grid item xs={12} md={8}>
			{articleData.body ? <div dangerouslySetInnerHTML={{ __html: articleData.body }} /> : ""}
			{articleData.dateTime ? <p style={{color: 'gray', fontSize: '0.8em', marginTop: '50px'}}>{articleData.dateTime.toDate().toLocaleDateString("en-US", {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p> : ""}
		</Grid>
		<Grid item xs={0} md={2}></Grid>

		</Grid>
		</section>

		</>


	)
}

import {initializeApp} from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyAM-JJEoppGGUGJgbMETYpUV0OTEmjU154",
  authDomain: "convex-hull-strategy.firebaseapp.com",
  projectId: "convex-hull-strategy",
  storageBucket: "convex-hull-strategy.appspot.com",
  messagingSenderId: "43562691496",
  appId: "1:43562691496:web:0932e3495a79b42cc9cb9d",
  measurementId: "G-Y3J9Y41122"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app)



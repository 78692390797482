import logo from './logo.svg';
import './App.css';
import mainMuiTheme from './mainMuiTheme'
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider';
import './App.css';
import { Header } from './Header'
import { Footer } from './Footer'
import { Home } from './Home'
import { Contact } from './Contact'
import { ArticlePage } from './ArticlePage'
import { InsightsIndex } from './InsightsIndex'
import { SiteTerms } from './SiteTerms'
import { Privacy } from './Privacy'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { db } from './firebaseDbProvider'
import Grid from '@mui/material/Grid'


function App() {
  return (
	  <>
    <div className="App">
	  <ThemeProvider theme={mainMuiTheme}>
	  <BrowserRouter>
	  <Header />
	  <main style={{minHeight: '80vh'}}>
		<section style={{padding: '50px 10px'}}>
		<Grid container>
		<Grid item xs={0} md={2}></Grid>
		<Grid item xs={12} md={8}>
	  <Routes>
		  <Route path="/" element={<Home db={db} />} />
		  <Route path="/insights" element={<InsightsIndex db={db} />} />
		  <Route path="/contact" element={<Contact db={db} />} />
		  <Route path="/news/:article_url" element={<ArticlePage db={db}pageType="news" />} />
		  <Route path="/insights/:article_url" element={<ArticlePage db={db} pageType="insights" />} />
		  <Route path="/site-terms" element={<SiteTerms />} />
		  <Route path="/privacy" element={<Privacy />} />


	  </Routes>
		</Grid>
		<Grid item xs={0} md={2}></Grid>

		</Grid>
		</section>
	
	  </main>
	  <Footer />
	  </BrowserRouter>
	  </ThemeProvider>
    </div>


	  </>
  );
}

export default App;

import Grid from '@mui/material/Grid'
import { HomePageBigLink } from './HomePageBigLink'
import { ArticlesList } from './ArticlesList'
import { Helmet } from 'react-helmet'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box'


export function Home({db}) {

	
	return(
		<>

		<Helmet>
		<title>Home | Convex Hull Strategy</title>
		<meta name="description" content="Finding better outcomes." />
		</Helmet>



		<div style={{}}>
	  <Box sx={{display: "flex", justifyContent: "center", marginTop: '150px', marginBottom: '70px'}}>
		<Divider color="var(--blue)" sx={{width: "100%", maxWidth: "300px", variant: "middle", backgroundColor: "white", height: 2}}  />
	  </Box>

	  	<Typography variant="h5" p={5} color="var(--blue)" align="center">
	  The convex hull in game theory represents the full range of strategy combinations that can be played. We identify all strategies available to our clients. It is often previously unseen strategies that prove to be successful.
	  	</Typography>


	  <Box mb={20} sx={{display: "flex", justifyContent: "center"}}>
		<Divider color="white" sx={{width: "100%", maxWidth: "300px", variant: "middle", backgroundColor: "white", height: 2, marginTop: '70px'}}  />
	  </Box>
		</div>

		</>
	)}




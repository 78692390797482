import { useEffect, useState } from 'react'
import { collection, getDocs, limit, query, where, orderBy, startAfter } from 'firebase/firestore'
import { HomePageBigLink } from './HomePageBigLink'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'


export function ArticlesList({db, indexType}) {

	const [ articlesList, setArticlesList ] = useState([])
	const [ lastDataPageSnapshot, setLastDataPageSnapshot ] = useState()

	let pageType
	if (indexType==="guides") {
		pageType = "guide"
	}
	if (indexType==="posts") {
		pageType = "insights"
	}



	// see why DB not passing to useeffect. must define function outside it?
	useEffect(() => {
		async function GetArticleData() {

			const dbCollection = indexType
			const articlesRef = collection(db, indexType)
			const queryToSubmit = query(articlesRef, orderBy("dateTime", "desc"), limit(5));
			
			/*USE DOCUMENTATION TO GET PROPER SYNTAX TO PULL COLLECTION*/
			/*OR TRY OTHER METHOD - LIVE */

			const articlesSnapshot = await getDocs(queryToSubmit)
			const lastVisible = articlesSnapshot.docs[articlesSnapshot.docs.length-1]
			setLastDataPageSnapshot(lastVisible)

			const articlesListPull = articlesSnapshot.docs.map(doc => { return { ...doc.data(), 'id': doc.id} })

			setArticlesList(articlesListPull)


		}
		GetArticleData();
	}, [db])

	async function populateNextData() {
			const articlesRef = collection(db, indexType)
	const queryToSubmit = query(articlesRef, orderBy("dateTime", "desc"), startAfter(lastDataPageSnapshot), limit(5));
			const articlesSnapshot = await getDocs(queryToSubmit)
			const lastVisible = articlesSnapshot.docs[articlesSnapshot.docs.length-1]
			setLastDataPageSnapshot(lastVisible)

			const articlesListPull = await articlesSnapshot.docs.map(doc => { return { ...doc.data(), 'id': doc.id} })

			setArticlesList([...articlesList, ...articlesListPull])


	}


	return(
		<>
		<Box sx={{marginTop: {xs: '-100px', md: '-20px'}}}>
		{articlesList.map(a => <HomePageBigLink text={a.name} url={a.url} pageType={pageType} />)}
		{lastDataPageSnapshot ? <Button variant="outlined" size='large' sx={{color: 'black', borderColor: 'black'}} onClick={populateNextData}>Load More</Button> : <></>}
		</Box>
		</>
	)

	}



